import { message } from "antd";
import { GlobalAction } from "../..";
import { Coordinates, PlotItem, PlotLine, SavePlotboardRequest } from "../../dao/OutlineDao";
import { PlotDesignerState } from "../storyline/PlotDesigner";
import { v4 as uuidv4 } from "uuid";
import { ConsoleLogger, localizer } from "di-common";
import jsSHA from "jssha"; //See: https://caligatio.github.io/jsSHA/

const logger = new ConsoleLogger("PlotDesignerReducer");

type GridSize = {
  width: number;
  height: number;
};

/** PlotItem and Plotline checksums are stored by their id. */
let persistedPlotCardChecksums: Map<string, string> = new Map();
let persistedPlotLineChecksums: Map<string, string> = new Map();

/**
 * The code in this module manages the state related to the PlotDesigner data.
 */
export default function plotDesignerReducer(draft: PlotDesignerState, action: GlobalAction) {
  switch (action.type) {
    case "newPlotBoard":
      draft.plotBoard = { columnCount: 5, rowCount: 10 };
      draft.plotItems = [];
      draft.plotLines = [];
      persistedPlotCardChecksums = getPlotItemChecksums(draft.plotItems);
      persistedPlotLineChecksums = getPlotLineChecksums(draft.plotLines);
      draft.isDirty = true;
      break;
    case "initialize":
      draft.plotItems = action.data.plotCards || [];
      draft.plotLines = action.data.plotLines || [];
      persistedPlotCardChecksums = getPlotItemChecksums(draft.plotItems);
      persistedPlotLineChecksums = getPlotLineChecksums(draft.plotLines);
      draft.isDirty = false;
      draft.plotBoard = action.data.plotBoard;
      draft.plotBoard.columnCount = Math.max(5, draft.plotBoard.columnCount);
      draft.plotBoard.rowCount = Math.max(10, draft.plotBoard.rowCount);
      break;
    case "movePlotItem":
      if (handleOverlappingGridCells(draft, action) === "abort") {
        break;
      }

      for (const plotItem of draft.plotItems) {
        if (plotItem.id === action.data.itemId) {
          const oldLocation: Coordinates = plotItem.location;
          const newLocation: Coordinates = action.data.insertLocation;
          if (oldLocation.x !== newLocation.x || oldLocation.y !== newLocation.y) {
            plotItem.location = newLocation;
            // check if we need to add a plotline
            if (draft.plotLines[newLocation.x] == null) {
              // insert plotline when necessary (card cannot exist without plotline)
              const newPlotLine: PlotLine = { id: uuidv4(), title: "", columnIndex: newLocation.x };
              draft.plotLines[newLocation.x] = newPlotLine;
            }
          }
          break;
        }
      }
      draft.isDirty = doDirtyCheck(draft);
      break;
    case "selectPlotItem":
      draft.selectedPlotLineId = undefined;
      draft.selectedPlotItemId = action.data;
      draft.isLeftAsideOpen = true;
      logger.debug("selectPlotItemId: " + action.data);
      break;
    case "movePlotLine":
      {
        const plotLine: PlotLine = getPlotLineById(draft.plotLines, action.data.plotLineId);
        const moveExistingRight: boolean = action.data.moveExistingRight === true;
        const oldColumnIndex: number = plotLine.columnIndex;
        const newColumnIndex: number = action.data.insertColumn;
        const protectXAxisColumnToMove = newColumnIndex <= oldColumnIndex && moveExistingRight;
        if (protectXAxisColumnToMove) {
          //protected columnIndex and plotcard locations from column to be moved to be changed with shifting right
          for (const item of draft.plotItems) {
            if (item.location.x === oldColumnIndex) {
              item.location.x = -1;
            }
            plotLine.columnIndex = -1;
          }
        }
        const isAdded: boolean = addPlotlineToPlotBoard(draft, plotLine, newColumnIndex, moveExistingRight);
        if (isAdded) {
          plotLine.columnIndex = newColumnIndex;
          if (!protectXAxisColumnToMove) {
            draft.plotLines[oldColumnIndex] = null;
          } else {
            draft.plotLines[oldColumnIndex + 1] = null;
          }
          const plotcardTargetColumn = protectXAxisColumnToMove ? -1 : oldColumnIndex;
          for (const item of draft.plotItems) {
            if (item.location.x === plotcardTargetColumn) {
              item.location.x = newColumnIndex;
            }
          }
          draft.isDirty = doDirtyCheck(draft);
        }
        logger.info(`movePlotLine ${action.data.plotLineId} from col. ${oldColumnIndex} -> ${newColumnIndex}: result = ${isAdded}`);
      }
      break;
    case "selectPlotLine":
      draft.selectedPlotLineId = action.data;
      draft.selectedPlotItemId = undefined;
      draft.isLeftAsideOpen = true;
      logger.debug("selectPlotLineId: " + action.data);
      break;
    case "closePlotDesignerDetails":
      closePlotDesignerDrawer(draft);
      break;
    case "updatePlotItem":
      logger.debug("Received updatePlotItem request for item " + action.data.id);
      for (const plotItem of draft.plotItems) {
        if (plotItem.id === action.data.id) {
          Object.assign(plotItem, action.data.changes);
          draft.isDirty = doDirtyCheck(draft);
          break;
        }
      }
      break;
    case "updatePlotLine":
      logger.debug("Received updatePlotLine request for plotline " + action.data.id);
      for (const plotLine of draft.plotLines) {
        if (plotLine && plotLine.id === action.data.id) {
          Object.assign(plotLine, action.data.changes);
          draft.isDirty = doDirtyCheck(draft);
          break;
        }
      }
      break;
    case "removePlotItem":
      removePlotItem(draft, action.data);
      draft.isDirty = doDirtyCheck(draft);
      break;
    case "removePlotLine":
      if (draft.selectedPlotLineId === action.data) {
        closePlotDesignerDrawer(draft);
      }

      //remove from collection of plotlines
      const plotLine = getPlotLineById(draft.plotLines, action.data);
      draft.plotLines[plotLine.columnIndex] = null;

      //remove plot cards that were present on that plotline
      //work from the end of the plotItems list to the beginning, so index is not
      //affected when plotItem is deleted
      for (let index = draft.plotItems.length; index--; index >= 0) {
        if (draft.plotItems[index].location.x === plotLine.columnIndex) {
          removePlotItem(draft, draft.plotItems[index].id);
        }
      }
      draft.isDirty = doDirtyCheck(draft);
      break;
    case "addPlotItem":
      const result: string | number = handleOverlappingGridCells(draft, action);
      if (result === "abort") {
        break;
      }
      const location: Coordinates = action.data.insertLocation;
      const newItem: PlotItem = { id: uuidv4(), recap: "", location };
      draft.plotItems.push(newItem);
      draft.selectedPlotItemId = newItem.id;
      draft.isLeftAsideOpen = true;

      if (draft.plotLines[location.x] == null) {
        // insert plotline when necessary (card cannot exist without plotline)
        const newPlotLine: PlotLine = { id: uuidv4(), title: "", columnIndex: location.x };
        draft.plotLines[location.x] = newPlotLine;
      }

      draft.isDirty = doDirtyCheck(draft);
      logger.debug(`add PlotItem with id ${newItem.id} @ cell (${action.data.insertLocation.x}, ${action.data.insertLocation.y})`);
      break;
    case "addPlotLine":
      {
        const columnIndex: number = action.data.columnIndex;
        const newPlotLine: PlotLine = { id: uuidv4(), title: "", columnIndex };
        if (addPlotlineToPlotBoard(draft, newPlotLine, columnIndex, action.data.moveExistingRight)) {
          draft.isDirty = doDirtyCheck(draft);
        }
        draft.selectedPlotLineId = newPlotLine.id;
        draft.isLeftAsideOpen = true;
      }
      break;
    case "saveSuccesfull":
      //we get the persisted state in the response, set it as new persisted baseline
      draft.pendingSaveRequest = undefined;
      logger.info("saveSuccesfull, data received:", action.data);
      draft.plotBoard = action.data.plotBoard;
      persistedPlotCardChecksums = getPlotItemChecksums(action.data.plotCards || []);
      persistedPlotLineChecksums = getPlotLineChecksums(action.data.plotLines || []);
      draft.isDirty = doDirtyCheck(draft);

      if (draft.saveSource === "user") {
        message.success(localizer.resolve("Global.feedback.saveSuccess"));
      }
      draft.saveSource = "none";
      break;
    case "saveFailed":
      draft.pendingSaveRequest = undefined;
      console.error("saveFailed, data received: ", action.data);
      if (draft.saveSource === "user") {
        message.error(localizer.resolve("Global.feedback.saveError"));
      }
      draft.saveSource = "none";
      break;
    case "autosave":
      if (draft.isDirty) {
        draft.saveSource = "autosave";
        savePlotboard(draft);
      }
      break;
    case "save":
      draft.saveSource = "user";
      savePlotboard(draft);
      break;
    default:
      console.error(`PlotDesigner: Unexpected action type: ${action.type}`);
  }
}

function closePlotDesignerDrawer(draft: PlotDesignerState) {
  draft.selectedPlotLineId = undefined;
  draft.selectedPlotItemId = undefined;
  draft.isLeftAsideOpen = false;
}

function addPlotlineToPlotBoard(
  draft: PlotDesignerState,
  plotlineToAdd: PlotLine,
  columnIndex: number,
  moveExistingRight: boolean
): boolean {
  if (moveExistingRight === true) {
    moveColumnsRight(draft, columnIndex);
  }

  if (draft.plotLines[columnIndex] == null) {
    draft.plotLines[columnIndex] = plotlineToAdd;
    return true;
  } else if (!moveExistingRight) {
    message.warning(localizer.resolve("PlotDesigner.messages.plotLineColumnOccupied"));
  }
  return false;
}

function removePlotItem(draft: PlotDesignerState, plotItemId: string) {
  if (draft.selectedPlotItemId === plotItemId) {
    closePlotDesignerDrawer(draft);
  }
  let targetIndex = -1;
  for (const [index, plotItem] of draft.plotItems.entries()) {
    if (plotItem.id === plotItemId) {
      targetIndex = index;
      break;
    }
  }
  if (targetIndex < 0) {
    throw new Error(`PlotItem with id ${plotItemId} does not exist`);
  }
  draft.plotItems.splice(targetIndex, 1);
  logger.debug("remove PlotItem with id: " + plotItemId);
}

function savePlotboard(draft: PlotDesignerState) {
  const request: SavePlotboardRequest = {
    plotBoard: draft.plotBoard,
    deletePlotlines: [],
    insertPlotlines: [],
    updatePlotlines: [],
    deletePlotcards: [],
    insertPlotcards: [],
    updatePlotcards: []
  };

  //Only send changes to plotcards and plotlines to server, not the entire plotboard
  const currentItemChecksums = getPlotItemChecksums(draft.plotItems);
  for (const [itemId, checksum] of persistedPlotCardChecksums.entries()) {
    if (!currentItemChecksums.has(itemId)) {
      // delete cards with id that is in persisted but not in current
      request.deletePlotcards.push(itemId);
    } else if (currentItemChecksums.get(itemId) !== checksum) {
      // update cards that have different location or checksum
      request.updatePlotcards.push(getPlotItemById(draft.plotItems, itemId));
    }
    currentItemChecksums.delete(itemId);
  }

  for (const itemId of currentItemChecksums.keys()) {
    // insert cards with id that is NOT in persisted but is in current
    request.insertPlotcards.push(getPlotItemById(draft.plotItems, itemId));
  }

  const currentPlotLineChecksums = getPlotLineChecksums(draft.plotLines);
  for (const [itemId, checksum] of persistedPlotLineChecksums.entries()) {
    if (!currentPlotLineChecksums.has(itemId)) {
      // delete plotlines with id that is in persisted but not in current
      request.deletePlotlines.push(itemId);
    } else if (currentPlotLineChecksums.get(itemId) !== checksum) {
      // update plotlines that have different location or checksum
      request.updatePlotlines.push(getPlotLineById(draft.plotLines, itemId));
    }
    currentPlotLineChecksums.delete(itemId);
  }

  for (const itemId of currentPlotLineChecksums.keys()) {
    // insert cards with id that is NOT in persisted but is in current
    request.insertPlotlines.push(getPlotLineById(draft.plotLines, itemId));
  }

  draft.pendingSaveRequest = request;
  draft.saveCount++;
  logger.info(
    `[request ${draft.saveCount}]: Saving plotcards: delete ${request.deletePlotcards.length}, insert ${request.insertPlotcards.length}, update ${request.updatePlotcards.length}`
  );
  logger.info(
    `[request ${draft.saveCount}]: Saving plotlines: delete ${request.deletePlotlines.length}, insert ${request.insertPlotlines.length}, update ${request.updatePlotlines.length}`
  );
}

//TODO: use generics to merge the following 2  methods into 1 (see Udemy course)
function getPlotItemById(plotItems: PlotItem[], itemId: string): PlotItem {
  for (const item of plotItems) {
    if (item.id === itemId) {
      return item;
    }
  }
  throw new Error("No PlotItem with id: " + itemId);
}

function getPlotLineById(plotLines: (PlotLine | null)[], itemId: string): PlotLine {
  for (const plotLine of plotLines) {
    if (plotLine && plotLine.id === itemId) {
      return plotLine;
    }
  }
  throw new Error("No PlotLine with id: " + itemId);
}

/**
 * If the control key was pressed, move all cards in the story line from the insert location
 * downwards one row, to make room for the new plot card. If not, check if the insert location
 * is occupied already and return with string literal "abort" if the insert location is occupied.
 * @param draft
 * @param action
 * @returns string literal 'abort', if the insert location is already occupied by a plot card
 *     or otherwise the number of plot items that were relocated
 */
function handleOverlappingGridCells(draft: PlotDesignerState, action: GlobalAction): string | number {
  let relocatedCount: number = 0;
  if (action.data.moveExistingDown === true) {
    logger.info("Pushing existing plot cards down the plot line");
    const targetAxisColumn = action.data.insertLocation.x;
    const moveFromThisRowDown = action.data.insertLocation.y;
    for (const plotItem of draft.plotItems) {
      if (plotItem.location.x === targetAxisColumn && plotItem.location.y >= moveFromThisRowDown) {
        plotItem.location.y++;
        relocatedCount++;
      }
    }
    if (relocatedCount > 0) {
      //expand the grid row size if necessary (currently it only auto-grows vertically)
      const minGridSize = getMinimalGridSize(draft);
      if (draft.plotBoard.rowCount < minGridSize.height) {
        draft.plotBoard.rowCount = minGridSize.height;
      }
    }
  } else if (isOccupiedByOther(draft, action.data.insertLocation, action.data.itemId)) {
    message.warning(localizer.resolve("PlotDesigner.messages.gridcellOccupied"));
    return "abort";
  }
  return relocatedCount;
}

function moveColumnsRight(draft: PlotDesignerState, startColumnIndex: number) {
  for (const plotItem of draft.plotItems) {
    if (plotItem.location.x >= startColumnIndex) {
      plotItem.location.x++;
    }
  }
  for (const plotLine of draft.plotLines) {
    if (plotLine && plotLine.columnIndex >= startColumnIndex) {
      plotLine.columnIndex++;
    }
  }
  draft.plotLines.splice(startColumnIndex, 0, null);
  const gridSize = getMinimalGridSize(draft);
  if (draft.plotBoard.columnCount < gridSize.width) {
    draft.plotBoard.columnCount = gridSize.width;
  }
}

function getMinimalGridSize(draft: PlotDesignerState): GridSize {
  let maxWidth = 5; //never go smaller than 5 columns (see also the initial state in PlotDesigner)
  let maxHeight = 10; // never go smaller than 10 rows (see also the initial state in PlotDesigner)
  for (const plotItem of draft.plotItems) {
    maxWidth = Math.max(maxWidth, plotItem.location.x + 1);
    maxHeight = Math.max(maxHeight, plotItem.location.y + 1);
  }
  // also check maxWidth against plotlines, because a plotline can exist without cards
  for (const plotLine of draft.plotLines) {
    if (plotLine) {
      maxWidth = Math.max(maxWidth, plotLine.columnIndex + 1);
    }
  }
  return { width: maxWidth, height: maxHeight };
}

function doDirtyCheck(draft: PlotDesignerState): boolean {
  const startTime: number = performance.now();

  // check if plot cards or plot lines have changed or this is a new plotboard
  let isDirty = draft.plotBoard.id == null;
  if (!isDirty) {
    isDirty = hasUnsavedChanges(getPlotItemChecksums(draft.plotItems), persistedPlotCardChecksums);
  }
  if (!isDirty) {
    isDirty = hasUnsavedChanges(getPlotLineChecksums(draft.plotLines), persistedPlotLineChecksums);
  }
  logger.info(
    `doDirtyCheck of ${draft.plotItems.length + draft.plotLines.length} items in approx. ${
      performance.now() - startTime
    } ms. isDirty = ${isDirty}`
  );
  return isDirty;
}

function hasUnsavedChanges(currentChecksums: Map<string, string>, persistedChecksums: Map<string, string>): boolean {
  let isDirty = false;
  if (currentChecksums.size !== persistedChecksums.size) {
    isDirty = true;
  } else {
    for (const [key, value] of currentChecksums) {
      const persistedHash = persistedChecksums.get(key);
      if (persistedHash !== value) {
        isDirty = true;
        break;
      }
    }
  }
  return isDirty;
}

/**
 * Calculate and collect the checksum of every plotItem on the grid in a map keyed by id
 */
function getPlotItemChecksums(plotItems: PlotItem[]): Map<string, string> {
  // for now: assume unique id values for plotitems and plotlines (no validation needed)
  const newChecksums: Map<string, string> = new Map();
  for (const plotItem of plotItems) {
    const key: string = plotItem.id;
    const checksum: string = calculatePlotItemChecksum(plotItem);
    newChecksums.set(key, checksum);
  }
  return newChecksums;
}
/**
 * Calculate and collect the checksum of every plotLine on the grid in a map keyed by id
 */
function getPlotLineChecksums(plotLines: (PlotLine | null)[]): Map<string, string> {
  const newChecksums: Map<string, string> = new Map();
  for (const plotLine of plotLines) {
    if (plotLine) {
      const key: string = plotLine.id;
      const checksum: string = calculatePlotLineChecksum(plotLine);
      newChecksums.set(key, checksum);
    }
  }
  return newChecksums;
}

/** Check if there is already a plotcard registered on the given grid cell */
function isOccupiedByOther(draft: PlotDesignerState, gridLocation: Coordinates, id?: string): boolean {
  for (const plotItem of draft.plotItems) {
    if (plotItem.location.x === gridLocation.x && plotItem.location.y === gridLocation.y) {
      return plotItem.id !== id;
    }
  }
  return false;
}

/**
 * Calculate a SHA-512 checksum of the fields of a PlotItem
 */
function calculatePlotItemChecksum(item: PlotItem & Record<string, string | undefined | Coordinates>): string {
  const propertiesToHash: string[] = ["id", "recap", "action", "motivation", "notes"];
  const hashFunction = new jsSHA("SHA-512", "TEXT");
  for (const propName of propertiesToHash) {
    hashFunction.update(propName + (item[propName] || ""));
  }
  //At the end: include the location in the hash as well
  hashFunction.update(`location${item.location.x},${item.location.y}`);

  return hashFunction.getHash("HEX");
}

/**
 * Calculate a SHA-512 checksum of the fields of a PlotLine
 */
function calculatePlotLineChecksum(plotLine: PlotLine & Record<string, string | undefined | number>): string {
  const propertiesToHash: string[] = ["id", "title", "background", "summary", "notes"];
  const hashFunction = new jsSHA("SHA-512", "TEXT");
  for (const propName of propertiesToHash) {
    hashFunction.update(propName + (plotLine[propName] || ""));
  }
  //At the end: include the column index in the hash as well
  hashFunction.update(`columnIndex_${plotLine.columnIndex}`);

  return hashFunction.getHash("HEX");
}
