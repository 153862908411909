import { AliasToken } from "antd/es/theme/internal";

// export const variables = {
//   titleFontFamily: "Lato",
//   primaryColor: "#00294e",
//   secondaryColor: "green",
//   bodyBackground: "#f0f0f0",
//   bodyBackgroundLighter: "#f5f5f5",
//   bodyBackgroundDarker: "#ececec",
//   secundairyBackground: "#dde0e2",
//   componentBackground: bodyBackground,
//   layoutBodyBackground: bodyBackground,
//   layoutHeaderBackground: bodyBackground,
//   heading1Size: "3.8rem",

//   //border within component
//   borderColorBase: secundairyBackground,

//   //border around components
//   borderColorSplit: secundairyBackground,

//   outlineColor: bodyBackground,

//   //breakpoints for mediaqueries
//   breakpointMedium: "800px",
//   breakpointSmallest: "400px"
// };

export const variables: Partial<AliasToken> = {
  colorPrimary: "#00294e",
  colorBgBase: "#f0f0f0",
  // colorBgContainer: "#f0f0f0",
  colorLink: "#00294e",
  colorLinkActive: "#00294e",
  fontFamily: "'Liberation Sans', sans-serif"
};
