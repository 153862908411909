import React, { useEffect, useState } from "react";
import { Button, Drawer, Tooltip } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import StorylinePane from "../storyline/StorylinePane";
import RelationPane from "../relation/RelationPane";
import { localizer } from "di-common";
import IconFont from "./IconFont";
import { CHARACTER, EVENT, GROUP, LOCATION, RELATION, THING } from "../entity/EntityConstants";
import EntityPane from "../entity/EntityPane";
import { CloseOutlined } from "@ant-design/icons";

type ActionBarProps = {
  characterOn?: boolean;
  groupOn?: boolean;
  eventOn?: boolean;
  locationOn?: boolean;
  thingOn?: boolean;
  relationOn?: boolean;
  storylineOn?: boolean;
  isDisabled?: boolean;
  setDrawerOpenCallback: React.Dispatch<React.SetStateAction<boolean>>;
  initialyOpenedSection?: string | null;
  contentTree?: React.JSX.Element;
};

function ActionBar({
  characterOn = true,
  groupOn = true,
  eventOn = true,
  locationOn = true,
  thingOn = true,
  relationOn = false,
  storylineOn = true,
  isDisabled = false,
  initialyOpenedSection = null,
  contentTree = undefined,
  // function to let caller know if drawer state is set to opened or closed
  setDrawerOpenCallback = function () {}
}: ActionBarProps) {
  const [drawerOpened, setDrawerOpened] = useState<string | null>(initialyOpenedSection || null);

  function handleButtonClick(key: string): void {
    if (drawerOpened === key) {
      setDrawerOpened(null);
      setDrawerOpenCallback(false);
    } else {
      setDrawerOpened(key);
      setDrawerOpenCallback(true);
    }
  }

  useEffect(() => {
    // Notify parent component that the
    setDrawerOpenCallback(drawerOpened != null);
  });

  return (
    <>
      <div className="sidepane-area">
        <div id="drawerContainer">
          {contentTree && (
            <Drawer
              title={localizer.resolve("ContentTree.title")}
              placement="right"
              getContainer={() => document.getElementById("drawerContainer") as HTMLElement}
              closable={true}
              onClose={() => handleButtonClick("ContentTree")}
              open={drawerOpened === "ContentTree"}
              mask={false}
              width="100%"
              closeIcon={
                <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
                  <CloseOutlined />
                </Tooltip>
              }
            >
              {contentTree}
            </Drawer>
          )}
          {storylineOn && (
            <StorylinePane
              isDisabled={isDisabled}
              isVisible={drawerOpened === "Storyline"}
              onClose={() => handleButtonClick("Storyline")}
            />
          )}
          {characterOn && (
            <EntityPane
              isDisabled={isDisabled}
              entityType={CHARACTER}
              isVisible={drawerOpened === CHARACTER}
              onClose={() => handleButtonClick(CHARACTER)}
            />
          )}
          {eventOn && (
            <EntityPane
              isDisabled={isDisabled}
              entityType={EVENT}
              isVisible={drawerOpened === EVENT}
              onClose={() => handleButtonClick(EVENT)}
            />
          )}
          {groupOn && (
            <EntityPane
              isDisabled={isDisabled}
              entityType={GROUP}
              isVisible={drawerOpened === GROUP}
              onClose={() => handleButtonClick(GROUP)}
            />
          )}
          {locationOn && (
            <EntityPane
              isDisabled={isDisabled}
              entityType={LOCATION}
              isVisible={drawerOpened === LOCATION}
              onClose={() => handleButtonClick(LOCATION)}
            />
          )}
          {relationOn && (
            <RelationPane
              isDisabled={isDisabled}
              isVisible={drawerOpened === RELATION}
              onClose={() => handleButtonClick(RELATION)}
            />
          )}
          {thingOn && (
            <EntityPane
              isDisabled={isDisabled}
              entityType={THING}
              isVisible={drawerOpened === THING}
              onClose={() => handleButtonClick(THING)}
            />
          )}
        </div>
      </div>
      <div className="actionbar-area">
        <div className="actionbar">
          {contentTree && (
            <Button
              className={["actionbar__button", drawerOpened === "ContentTree" ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick("ContentTree")}
              title={localizer.resolve("ContentTree.title")}
              type="link"
              icon={<BarsOutlined />}
            />
          )}
          {storylineOn && (
            <Button
              className={["actionbar__button", drawerOpened === "Storyline" ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick("Storyline")}
              title={localizer.resolve("Outline.title")}
              type="link"
              icon={<IconFont type="icon-Storyline" size={32} />}
            />
          )}
          {characterOn && (
            <Button
              className={["actionbar__button", drawerOpened === CHARACTER ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(CHARACTER)}
              title={localizer.resolve("Character.title")}
              type="link"
              icon={<IconFont type="icon-Character" size={32} />}
            />
          )}
          {groupOn && (
            <Button
              className={["actionbar__button", drawerOpened === GROUP ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(GROUP)}
              title={localizer.resolve("Group.title")}
              type="link"
              icon={<IconFont type="icon-Group" size={32} />}
            />
          )}
          {locationOn && (
            <Button
              className={["actionbar__button", drawerOpened === LOCATION ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(LOCATION)}
              title={localizer.resolve("Location.title")}
              type="link"
              icon={<IconFont type="icon-Location" size={32} />}
            />
          )}
          {eventOn && (
            <Button
              className={["actionbar__button", drawerOpened === EVENT ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(EVENT)}
              title={localizer.resolve("Event.title")}
              type="link"
              icon={<IconFont type="icon-Event" size={32} />}
            />
          )}
          {thingOn && (
            <Button
              className={["actionbar__button", drawerOpened === THING ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(THING)}
              title={localizer.resolve("Thing.title")}
              type="link"
              icon={<IconFont type="icon-Thing" size={32} />}
            />
          )}
          {relationOn && (
            <Button
              className={["actionbar__button", drawerOpened === RELATION ? "actionbar__button--active" : ""].join(" ")}
              onClick={() => handleButtonClick(RELATION)}
              title={localizer.resolve("Relation.title")}
              type="link"
              icon={<IconFont type="icon-Relation" size={32} />}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ActionBar;
