import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { createHashRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";
import { useImmerReducer, useImmer } from "use-immer";
import { DateTime } from "luxon";
import { ConsoleLogger, localizer, NotFound } from "di-common";
import StateContext, { LoginInfo, UserPreferences } from "./StateContext";
import DashboardView from "./components/dashboard/DashboardView";
import { isAccountFrozen, getValidTillDate } from "./components/user/UserUtils";
import userDao from "./dao/UserDao";
import Ideabook from "./components/ideabook/Ideabook";
import { AppInfo, SessionConstants } from "./Constants";
import DispatchContext from "./DispatchContext";
import IdeaDetails from "./components/ideabook/IdeaDetails";
import Fragments from "./components/fragments/Fragments";
import Fragment from "./components/fragments/Fragment";
import LocalizedStatic from "./components/general/LocalizedStatic";
import ProjectView from "./components/projects/ProjectView";
import PaymentResultPanel from "./components/user/PaymentResultPanel";
import CreateAccountForm from "./components/user/CreateAccountForm";
import ActivationAction from "./components/user/ActivationAction";
import MainLayout from "./layouts/MainLayout";
import { ConfigProvider, message } from "antd";
import Axios from "axios";
import errorInterceptor from "./utils/NetworkErrorHandler";
import PlotDesigner from "./components/storyline/PlotDesigner";
import "./main.less";
import { token } from "./styling/maincss";

const logger = new ConsoleLogger("DigitalInkUI");

/**
 * Keep this value in sync with the CSS in src/styling/abstracts/variables.less,
 * which is used in media queries
 */
const breakpointSmallest = 400;

message.config({
  duration: 4,
  maxCount: 2
});

/**
 * A multifunctional action to consume by reducers
 * @param T the type of the data that is caried by this action
 */
export type GlobalAction<T = any> = {
  /** this value determines which operation is executed in the reducer*/
  type: string;
  /** the data caried by this GlobalAction */
  data?: T;
  messageKey?: string;
  value?: string;
};

function DigitalInkUI() {
  localizer.registerLocaleChangeListener("DigitalInkUI", onLocaleChanged);

  //Message to display on Login screen when user is logged out by the server
  const [exitMessage, setExitMessage] = useState<string | undefined>(undefined);

  function loginReducer(draft: LoginInfo, action: GlobalAction): void {
    switch (action.type) {
      case "needsAuthentication":
        draft.needsAuthentication = Boolean(action.data);
        break;
      case "login":
        draft.isLoggedIn = true;
        draft.currentUser = action.data;
        break;
      case "feedback":
        logger.info("Reporting feedback: " + action.messageKey);
        if (action.messageKey) {
          message.info(localizer.resolve(action.messageKey));
        }
        break;
      case "serverLogout":
        if (action.messageKey) {
          setExitMessage(localizer.resolve(action.messageKey));
        }
        logout(draft);
        break;
      case "userLogout":
        logout(draft);
        break;
      case "changeUserInfo":
        draft.currentUser = action.data;
        sessionStorage.setItem(SessionConstants.USER_KEY, JSON.stringify(action.data));
        break;
      case "freeze":
        draft.isAccountFrozen = true;
        break;
      case "unfreeze":
        draft.isAccountFrozen = false;
        break;
      case "changeUiLocale":
        if (typeof action.value === "string") {
          draft.renderLocale = action.value;
          logger.info("Saving uiLocale to localStorage: " + action.value);
          localStorage.setItem("uiLocale", action.value);
        }
        break;
      default:
        break;
    }
  }

  function logout(draft: LoginInfo) {
    draft.isLoggedIn = false;
    sessionStorage.clear();
    draft.currentUser = null;
    Axios.get("/api/public/user/logout").then(
      response => logger.info("You have been logged out"),
      error => console.dir(error)
    );
  }

  const [state, dispatch] = useImmerReducer(loginReducer, initializeState());

  const [userPreferences, updateUserPreferences] = useImmer<UserPreferences>({});

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  // State to allow different component layout based on screen width
  const [isBelowBreakpointSmallest, setBelowBreakpointSmallest] = useState(window.innerWidth <= breakpointSmallest);

  const updateWindowSize = () => {
    setBelowBreakpointSmallest(window.innerWidth <= breakpointSmallest);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  });

  useEffect(() => {
    if (state.isLoggedIn) {
      //Store current user data in session storage to survive page refresh
      sessionStorage.setItem(SessionConstants.USER_KEY, JSON.stringify(state.currentUser));
    } else {
      sessionStorage.removeItem(SessionConstants.USER_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoggedIn]);

  useEffect(() => {
    Axios.interceptors.response.use(
      response => response,
      error => errorInterceptor(dispatch, error)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      //Determine if the account is frozen
      const validTillDate = getValidTillDate(state.currentUser);
      const isFrozen = isAccountFrozen(state.currentUser);
      dispatch({ type: isFrozen ? "freeze" : "unfreeze" });
      if (!isFrozen && validTillDate) {
        const milliesBeforeExpiration = validTillDate.diff(DateTime.utc()).toMillis();
        logger.info("Account becomes invalid after " + milliesBeforeExpiration + " millies");

        // Attention: the timeout can only be a signed 32bit number, therefor, the max. timeout millies is 2147483647.
        // Bigger numbers could trigger runnning the timeout function immediately
        const timer = setTimeout(() => dispatch({ type: "freeze" }), Math.min(milliesBeforeExpiration, 2147483647));

        //return a useEffect cleanup function to clear the timeout
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentUser]);

  useEffect(() => {
    //This saves the settings when they are 'loaded', also when they have not changed
    if (settingsLoaded && state.currentUser) {
      //save user settings with a little delay when they have changed, so that it is not triggered after every keystroke
      const delay = setTimeout(
        userDao.saveSettings, //function to call
        2000, //millies to wait to call above function
        state.currentUser!, //this and lines below: the params to pass to function to call
        userPreferences,
        () => {
          logger.info("User settings are saved");
        },
        (error: any) => {
          console.error(error);
        }
      );

      //The useEffect cleanup function:
      return () => clearTimeout(delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);

  useEffect(() => {
    if (userPreferences.uiLocale) {
      logger.info("Setting uiLocale from UserPreferences: " + userPreferences.uiLocale);
      localizer.setLocale(userPreferences.uiLocale as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.uiLocale]);

  useEffect(() => {
    //load user settings on startup
    if (state.currentUser && !settingsLoaded) {
      userDao.loadSettings(
        state.currentUser,
        (response: any) => {
          updateUserPreferences(draft => response.data); //replace draft with data from response
          setSettingsLoaded(true);
        },
        (error: any) => console.error(error)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentUser, settingsLoaded]);

  /**
   * This is a callback function that is called by the localizer when translations have been loaded
   * for a newly selected ProjectLanguageFormItem, and thus the GUI can be re-rendered
   */
  function onLocaleChanged(newUiLocale: string) {
    if (typeof newUiLocale === "string") {
      logger.info(`Changing locale from ${state.renderLocale} to ${newUiLocale}`);
      dispatch({ type: "changeUiLocale", value: newUiLocale });
    }
  }

  function initializeState(): LoginInfo {
    const currentUser = sessionStorage.getItem(SessionConstants.USER_KEY)
      ? JSON.parse(sessionStorage.getItem(SessionConstants.USER_KEY)!)
      : null;
    return {
      needsAuthentication: true,
      isLoggedIn: Boolean(sessionStorage.getItem(SessionConstants.USER_KEY)),
      currentUser: currentUser,
      renderLocale: undefined,
      isAccountFrozen: isAccountFrozen(currentUser)
    };
  }

  const router = createHashRouter(
    createRoutesFromElements(
      <>
        <Route path="/agreement" element={<LocalizedStatic folderName="agreement" pageName="terms_of_use" />} />
        <Route path="/privacy" element={<LocalizedStatic folderName="privacy" pageName="statement" />} />
        <Route path="/aboutus" element={<LocalizedStatic folderName="aboutus" pageName="aboutus" />} />
        <Route path="/register" element={<CreateAccountForm />} />
        <Route path="/activate/:userId/:activationCode" element={<ActivationAction />} />
        <Route
          path="/"
          element={<MainLayout isLocked={state.isAccountFrozen} isLoggedIn={state.isLoggedIn} exitMessage={exitMessage} />}
        >
          <Route index element={<DashboardView isDisabled={state.isAccountFrozen} />} />
          <Route path="ideas" element={<Ideabook isDisabled={state.isAccountFrozen} />} />
          <Route path="fragments" element={<Fragments isDisabled={state.isAccountFrozen} />} />
          <Route path="fragment/:fragmentId" element={<Fragment isDisabled={state.isAccountFrozen} />} />
          <Route path="idea/:projectId" element={<IdeaDetails isDisabled={state.isAccountFrozen} />} />
          <Route path="projects/:projectId" element={<ProjectView isDisabled={state.isAccountFrozen} />} />
          <Route path="plot/:projectId" element={<PlotDesigner isDisabled={state.isAccountFrozen} />} />
          {state.currentUser && (
            <Route path="payment/:paymentId" element={<PaymentResultPanel currentUser={state.currentUser} dispatch={dispatch} />} />
          )}
        </Route>
        <Route path="*" element={<NotFound />} />
      </>
    )
  );
  return (
    // <StrictMode>
    <ConfigProvider theme={token}>
      <StateContext.Provider value={{ isBelowBreakpointSmallest, state, userPreferences }}>
        <DispatchContext.Provider value={{ dispatch, updateUserPreferences }}>
          <RouterProvider router={router} />
        </DispatchContext.Provider>
      </StateContext.Provider>
    </ConfigProvider>
    // </StrictMode>
  );
}

const containerElement = document.getElementById("root");
if (containerElement) {
  console.log(`Starting DigitalInkUI version ${AppInfo.version} in ${process.env.NODE_ENV} mode`);
  const root = createRoot(containerElement);
  root.render(<DigitalInkUI />);
} else {
  throw new Error("No element with id #root in index.html");
}
