import Axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { CHARACTER, EVENT, EntityTypes, GROUP, LOCATION, RELATION, THING } from "../components/entity/EntityConstants";
import dayjs from "dayjs";
import { User } from "../StateContext";

/**
 * DAO for entities that follow a REST naming convention for simple CRUD operations. Entities that
 * meet these demands are E.g. characters, locations, groups, events and things.
 */

type AxiosResponseTransformer = (response: AxiosResponse<any, any>) => AxiosResponse<any, any>;

export type Entity = {
  id: string;
  projectId: string;
  name: string;
  roleInStory?: string;
  avatarUrl?: string;
};

export type GenderType = "MALE" | "FEMALE" | "TRANS";

export type IdentityProps = {
  name: string;
  age?: number;
  gender?: GenderType;
};

export type Character = Entity &
  IdentityProps & {
    nickNames?: string[];
    biography?: string;
  };

class EntityDao {
  private entityName: string;
  private responseTransformer: AxiosResponseTransformer;
  constructor(singularEntityName: string, responseTransformer: AxiosResponseTransformer) {
    this.entityName = singularEntityName.toLowerCase();
    this.responseTransformer = responseTransformer;
  }

  loadEntityList(projectId: string, currentUser: User, responseCallback: any, errorCallback: any) {
    Axios.get(`/api/private/${this.entityName}s/${currentUser.id}/${projectId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  loadEntity(entityId: string, currentUser: User, responseCallback: any, errorCallback: any) {
    Axios.get(`/api/private/${this.entityName}/${currentUser.id}/${entityId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(response => responseCallback(this.responseTransformer(response)), errorCallback);
  }

  insertOrUpdateEntity(projectId: string, entity: Entity, currentUser: User, responseCallback: any, errorCallback: any) {
    let operationType: string;
    if (typeof entity.id === "string") {
      operationType = "put"; //do an update
    } else {
      operationType = "post"; //do an insert
      entity.id = uuidv4();
      entity.projectId = projectId;
    }
    Axios({
      method: operationType,
      url: `/api/private/${this.entityName}/${currentUser.id}`,
      data: entity,
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  deleteEntity(projectId: string, entityId: string, currentUser: User, responseCallback: any, errorCallback: any) {
    Axios.delete(`/api/private/${this.entityName}s/${currentUser.id}/${projectId}/${entityId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }
}

function transformConstructionDate(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  if (response && response.data && response.data.dateOfConstruction) {
    response.data.dateOfConstruction = dayjs(response.data.dateOfConstruction);
  }
  return response;
}

function transformCreationDates(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  if (response && response.data && response.data.dateOfConstruction) {
    response.data.dateOfConstruction = dayjs(response.data.dateOfConstruction);
  }
  return response;
}

function transformBirthDates(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  if (response && response.data && response.data.dateOfBirth) {
    response.data.dateOfBirth = dayjs(response.data.dateOfBirth);
  }
  return response;
}

function transformFormationDates(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  if (response && response.data && response.data.dateOfFormation) {
    response.data.dateOfFormation = dayjs(response.data.dateOfFormation);
  }
  return response;
}

function transformStartDates(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  if (response && response.data && response.data.startDate) {
    //we only have to convert the start date string to a date instance
    response.data.startDate = dayjs(response.data.startDate);
  }
  return response;
}

function noneTransformer(response: AxiosResponse<any, any>): AxiosResponse<any, any> {
  // Nothing to transform
  return response;
}

const characterDao = new EntityDao("character", transformBirthDates);
const groupDao = new EntityDao("group", transformFormationDates);
const locationDao = new EntityDao("location", transformConstructionDate);
const eventDao = new EntityDao("event", noneTransformer);
const thingDao = new EntityDao("thing", transformCreationDates);
const relationDao = new EntityDao("relation", transformStartDates);

export function getDao(entityType: EntityTypes) {
  switch (entityType) {
    case CHARACTER:
      return characterDao;
    case EVENT:
      return eventDao;
    case GROUP:
      return groupDao;
    case LOCATION:
      return locationDao;
    case RELATION:
      return relationDao;
    case THING:
      return thingDao;
    default:
      //Can't seem to get this to work with the never type, because the constants are not resolved before the exhaustive check?
      throw new Error(`No dao registered for entity type '${entityType}'`);
  }
}
