export const AppInfo = Object.freeze({
  appName: "Digital-Ink",
  version: "v2024.09.12",
  supportMail: "support@digital-ink.app"
});

export const SessionConstants = Object.freeze({
  USER_KEY: "currentUser",

  /**
   * This key holds a dispatch event that is targeted at the ContentPane component. It is used to trigger
   * events from outside. Must be used in combination with a property on the ContentPane to trigger
   * the process.
   */
  CONTENTPANE_EVENT_KEY: "contentPaneDispatchEvent"
});
