import React, { useEffect, useRef } from "react";
import { PlotItem } from "../../dao/OutlineDao";
import { Drawer, Form, Input, Tooltip } from "antd";
import { localizer } from "di-common";
import { GlobalAction } from "../..";
import { CloseOutlined } from "@ant-design/icons";

type PlotItemDetailsProps = {
  isDisabled?: boolean;
  isVisible?: boolean;
  plotItem?: PlotItem;
  onClose: () => void;
  dispatcher: React.Dispatch<GlobalAction>;
};

function getEmptyPlotItem(): PlotItem {
  return { id: "", location: { x: 0, y: 0 }, recap: "", action: "", motivation: "", notes: "" };
}
/**
 * Render a form on the left side to maintain the data of a PlotItem
 */
export function PlotItemDetails({
  isDisabled = false,
  isVisible = true,
  plotItem = getEmptyPlotItem(),
  dispatcher,
  onClose
}: PlotItemDetailsProps) {
  const [form] = Form.useForm();
  //Keep track of the PlotItem that is cerrently rendered in the
  const renderedIdRef = useRef(plotItem.id);

  /* For some unknown reason, the form is not rendered anew with updated (initial)
   * values, when the plotItem prop is different than previous render. Therefore, we
   * have this useEffect to accomplish this.
   */
  useEffect(() => {
    if (renderedIdRef.current !== plotItem.id) {
      form.setFieldsValue({ ...getEmptyPlotItem(), ...plotItem });
      renderedIdRef.current = plotItem.id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotItem.id]);

  function updatePlotItem(changedFields: any): void {
    if (plotItem.id) {
      dispatcher({ type: "updatePlotItem", data: { id: plotItem.id, changes: changedFields } });
    }
  }

  return (
    <Drawer
      className="plotcard__details"
      title={localizer.resolve("PlotItemDetails.title")}
      placement="left"
      getContainer={() => document.querySelector(".left-aside") as HTMLElement}
      closable={true}
      onClose={onClose}
      open={isVisible}
      mask={false}
      width="100%"
      closeIcon={
        <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
          <CloseOutlined />
        </Tooltip>
      }
    >
      <Form
        form={form}
        initialValues={plotItem}
        layout="vertical"
        onValuesChange={changedFields => updatePlotItem(changedFields)}
        preserve
        size="small"
      >
        <Form.Item
          name="recap"
          label={localizer.resolve("PlotItem.recap.label")}
          rules={[
            {
              max: 255,
              message: localizer.resolve("PlotItem.recap.message.maxLength")
            },
            {
              required: true,
              message: localizer.resolve("PlotItem.recap.message.mandatory")
            }
          ]}
        >
          <Input.TextArea rows={4} maxLength={256} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="action"
          label={localizer.resolve("PlotItem.action.label")}
          extra={localizer.resolve("PlotItem.action.help")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="motivation"
          label={localizer.resolve("PlotItem.motivation.label")}
          extra={localizer.resolve("PlotItem.motivation.help")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="notes"
          label={localizer.resolve("PlotItem.notes.label")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
