import React, { useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import DeleteResourceAction from "../general/DeleteResourceAction";
import { useImmerReducer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import { Card, Form, Input, message, Modal, Spin, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import fragmentDao, { FragmentExcerpt } from "../../dao/FragmentDao";
import { GlobalAction } from "../..";
import { ConsoleLogger } from "di-common";

const logger = new ConsoleLogger("Fragments");

type FragmentsState = {
  isLoading: boolean;
  showNewFragmentDialog: boolean;
  newFragmentTitle: string | null;
  isCreating: boolean;
  creationFailed: boolean;
  fragments: FragmentExcerpt[];
};

function Fragments({ isDisabled = false }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const initialState: FragmentsState = {
    isLoading: true,
    showNewFragmentDialog: false,
    newFragmentTitle: null,
    isCreating: false,
    creationFailed: false,
    fragments: []
  };

  // const
  function localReducer(draft: FragmentsState, action: GlobalAction): void {
    logger.debug("localReducer: processing action :", action.type);
    switch (action.type) {
      case "fragmentsLoaded":
        draft.fragments = action.data;
        draft.isLoading = false;
        break;
      case "loadingFailed":
        console.error("There was a problem loading the fragments: " + action.data);
        draft.isLoading = false;
        break;
      case "newDialog":
        draft.showNewFragmentDialog = true;
        break;
      case "createFragment":
        draft.newFragmentTitle = action.data.title;
        draft.isCreating = true;
        break;
      case "cancelNewFragment":
        draft.showNewFragmentDialog = false;
        break;
      case "fragmentCreated":
        draft.isCreating = false;
        draft.showNewFragmentDialog = false;
        draft.creationFailed = false;
        navigate(`/fragment/${action.data}`);
        break;
      case "failedCreatingFragment":
        draft.isCreating = false;
        draft.showNewFragmentDialog = false;
        draft.creationFailed = true;
        break;
      case "removefragmentsuccess":
        draft.fragments = action.data; //we receive back current list of fragments
        break;
      default:
        console.error(`Fragments: Unexpected action type: ${action.type}`);
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(localReducer, initialState);

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  useEffect(() => {
    //Load the list of current fragments for display
    if (currentUser) {
      fragmentDao.loadFragmentList(
        currentUser,
        (response: any) => dispatch({ type: "fragmentsLoaded", data: response.data }),
        (error: any) => dispatch({ type: "loadingFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isCreating && state.newFragmentTitle && currentUser) {
      //Create a new fragment on the server - the server responds with the UUID of the newly created fragment
      fragmentDao.insertFragment(
        {
          id: uuidv4(),
          title: state.newFragmentTitle,
          fragment: "",
          wordCount: 0
        },
        currentUser,
        (response: any) => dispatch({ type: "fragmentCreated", data: response.data }),
        (error: any) => dispatch({ type: "failedCreatingFragment", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCreating, state.newFragmentTitle]);

  if (state.isLoading) {
    return <Spin size="large" />;
  }

  const { Meta } = Card;
  const { Text } = Typography;

  return (
    <>
      <span>
        <Link className="link-area" to="/">
          &laquo; {localizer.resolve("Global.linkText.backToDashboard")}
        </Link>
      </span>
      <main className="dashboardview">
        <Card
          className="projectcard"
          hoverable={!state.showNewFragmentDialog && !isDisabled}
          onClick={
            isDisabled
              ? () => message.info(localizer.resolve(`Global.message.${currentUser?.reasonBecomingInvalid}`))
              : () => dispatch({ type: "newDialog" })
          }
          cover={<img alt="" src="images/keyboard.svg" />}
          actions={[<PlusOutlined key="add" />]}
        >
          <Meta title={localizer.resolve("Fragments.addTitle")} />
        </Card>
        {state.fragments.map(fragment => {
          return (
            <Card
              key={fragment.id}
              className="projectcard"
              onClick={() => navigate(`/fragment/${fragment.id}`)}
              hoverable
              actions={[
                <DeleteResourceAction
                  title={localizer.resolve("Fragment.deleteConfirmation")}
                  urlResourceName="fragments"
                  resourceId={fragment.id}
                  dispatcher={dispatch}
                  actionType="removefragmentsuccess"
                />
              ]}
            >
              <Card.Meta
                title={
                  <Text
                    style={{ width: "100%" }}
                    ellipsis={{
                      tooltip: fragment.title
                    }}
                  >
                    {fragment.title}
                  </Text>
                }
              />
              {fragment.fragmentExcerpt}
            </Card>
          );
        })}
      </main>
      <Modal
        title={localizer.resolve("Fragments.addTitle")}
        open={state.showNewFragmentDialog}
        okText={localizer.resolve("Global.buttonCaption.create")}
        cancelText={localizer.resolve("Global.buttonCaption.cancel")}
        onOk={() => form.submit()}
        confirmLoading={state.isCreating}
        onCancel={() => dispatch({ type: "cancelNewFragment" })}
        destroyOnClose={true}
      >
        <Form
          form={form}
          preserve={false}
          initialValues={{ title: "" }}
          onFinish={values => dispatch({ type: "createFragment", data: values })}
        >
          <Form.Item
            label={localizer.resolve("Fragment.title.label")}
            name="title"
            rules={[
              {
                max: 254,
                message: localizer.resolve("Fragment.title.message.maxLength")
              },
              {
                required: true,
                message: localizer.resolve("Fragment.title.message.mandatory")
              }
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Fragments;
